:root {
    /* ==================================================================================================== */
    /* Component Scss */

    //ion-app {
    //    margin-bottom: env(safe-area-inset-bottom);
    //}

    ion-refresher {
        background-color: var(--ion-color-refresher-background);
        //background-repeat: no-repeat;
        //background-size: cover;
        //background-position: center center;
        height: 100%;
        z-index: 5;
        //display: none;
    }
    ion-refresher-content {
        //display: block;
        //position: relative;
    }

    .refresher-refreshing-icon > ion-spinner {
    }
    .refresher-refreshing-text {
        font-weight: bold;
        font-size: 1.4rem;
    }

    ion-toolbar ion-searchbar {
        padding: 0 !important;
    }

    ion-list {
        margin: 0 !important;
        padding: 0 !important;
        background: transparent !important;
        overflow: hidden;
    }

    ion-card-content {
        width: 100%;
    }

    ion-item {
        --padding-start: 0;
        --padding-end: 0;
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --highlight-color-valid: transparent;
        --highlight-color-invalid: transparent;
        --highlight-color-focused: var(--ion-color-light);
    }

    ion-list-header {
        padding: 0;
    }

    ion-item-divider {
        background: transparent;
        padding: 0;
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        border: none;
    }

    ion-popover{
        ion-backdrop{
            opacity: 0.5 !important;
        }
        .popover-wrapper{
            .popover-content{
                width:100%;
                background: var(--ion-color-primary);

                top:   var(--ion-safe-area-top) !important;
                left:  0 !important;
                right: 0 !important;
            }
        }
    }

    ion-content.selectable {
        user-select: text;
        -webkit-user-select: text;
    }

    section#content-container {
        height: 100%;
    }

    /* ==================================================================================================== */
    /* Custom Class Scss */

    .vh-center {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .vh-full {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .full-width {
        width: 100%;
    }
    .full-height {
        height: 100%;
    }
    .middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-left {
        margin-right: 5px;
    }

    .bar-title {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }

    img.item-icon {
        min-height: 100%;
        min-width: 100%;
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
    }

    .menu-detail-icon {
        color: var(--ion-color-light) !important;
        opacity: 1 !important;
    }

    .main-logo {
        display: block;
        margin: 0 auto;
        padding: 20px 0;
    }

    .main-title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--ion-color-devotion-title);
        font-weight: bold;
        font-size: 24px;
        padding: 10px;
    }

    .main-content {
        padding: 0 20px;
        text-align: justify;

        .heading {
            color: var(--ion-color-devotion-verses);
            padding: 10px 0;
        }

        .title {
            background-color: var(--ion-color-block-background);
            color: var(--ion-color-primary);
            padding: 10px 0;
        }

        ul {
            padding-left: 20px;
        }

        ol {
            padding-left: 20px;
        }

        a {
            color: blue;
        }
    }

    .main-indicator {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--ion-color-primary);
        color: var(--ion-color-light);
        font-size: 24px;
        padding: 10px 0;
    }

    .alert-indicator {
        padding: 0.5rem;
        background: var(--ion-color-danger);
        color: var(--ion-color-text-white);
        word-break: break-all;

        a {
            //color: var(--ion-color-alert-link);
        }

        img {
            width: 100% !important;
        }
    }

    .alert-secondary {
        background-color: var(--ion-color-secondary) !important;
        color: var(--ion-color-text-white) !important;
    }

    .error-message {
        color: var(--ion-color-danger);
    }

    .caution {
        color: var(--ion-color-danger) !important;
    }

    .primary {
        color: var(--ion-color-primary) !important;
    }

    .excerpt {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .empty-list {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--ion-color-medium);
        font-size: 3rem;
        font-weight: bold;
    }
    .empty-vlist {
        @extend .empty-list;
        flex-direction: column;
    }
    .empty-label {
        width: 100%;
        font-size: 1.2rem;
        text-align: center;
    }

    .loading-separator {
        height: 1px;
        border: none;
        color: var(--ion-color-medium);
        background-color: var(--ion-color-medium);
    }
    .loading-note {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--ion-color-medium);
        font-size: 1rem;
        font-weight: bold;
    }

    div.template-content {
        height: 100%;
        padding: 10px 20px;
    }

    ion-slides {
        .swiper-slide, .swiper-pagination {
            --bullet-background: var(--app-background-alt);
            --bullet-background-active: var(--app-background-alt-shade);
        }
    }

    .swiper-pagination-bullet {
        opacity: 1;
    }

    ion-icon.video {
        color: var(--ion-color-video);
    }
    ion-icon.audio {
        color: var(--ion-color-audio);
    }
    ion-icon.document {
        color: var(--ion-color-document);
    }
    ion-icon.link {
        color: var(--ion-color-text-link);
    }
    // For Barcode plugin
    body.scanner-active {
        --background: transparent;
        --ion-background-color: transparent;
    }
}
