@import "./mixins/ion-color";

// Add custom colors to use with [color] property
// (see: https://forum.ionicframework.com/t/adding-custom-colours-to-use-with-color-property/136919)
// GLOBAL COLORS:
//    You can define new Ionic colors here (inside :root{ ... }) to be available within the entire app
// LOCAL COLORS:
//    You can also define new Ionic colors in each page (inside :host{ ... }) to be available just in that page
//    (We have an example of local custom Ionic colors in the deals pages)
:root {
    @include ion-color('wkphc') {
        --ion-color-wkphc: #996097;
        --ion-color-wkphc-rgb: 153,96,151;
        --ion-color-wkphc-contrast: #ffffff;
        --ion-color-wkphc-contrast-rgb: 255,255,255;
        --ion-color-wkphc-shade: #875485;
        --ion-color-wkphc-tint: #a370a1;
        --ion-color-wkphc-dk: #67278B;
        --ion-color-wkphc-lt: #EDE5F6;
    }
    @include ion-color('easter-stage-1') {
        --ion-color-easter-stage-1: #7BC7DE;
        --ion-color-easter-stage-1-rgb: 123,199,222;
        --ion-color-easter-stage-1-contrast: #FFFFFF;
        --ion-color-easter-stage-1-contrast-rgb: 0,0,0;
        --ion-color-easter-stage-1-shade: #6cafc3;
        --ion-color-easter-stage-1-tint: #88cde1;
        --ion-color-easter-stage-1-dk: #387695;
    }
    @include ion-color('easter-stage-2') {
        --ion-color-easter-stage-2: #97CD43;
        --ion-color-easter-stage-2-rgb: 151,205,67;
        --ion-color-easter-stage-2-contrast: #FFFFFF;
        --ion-color-easter-stage-2-contrast-rgb: 0,0,0;
        --ion-color-easter-stage-2-shade: #85b43b;
        --ion-color-easter-stage-2-tint: #a1d256;
        --ion-color-easter-stage-2-dk: #62843B;
    }
    @include ion-color('easter-stage-3') {
        --ion-color-easter-stage-3: #FF8024;
        --ion-color-easter-stage-3-rgb: 255,128,36;
        --ion-color-easter-stage-3-contrast: #FFFFFF;
        --ion-color-easter-stage-3-contrast-rgb: 0,0,0;
        --ion-color-easter-stage-3-shade: #e07120;
        --ion-color-easter-stage-3-tint: #ff8d3a;
        --ion-color-easter-stage-3-dk: #DB5C00;
    }

    @include ion-color('hymn') {
        --ion-color-hymn: #DA6363;
        --ion-color-hymn-rgb: 218,99,99;
        --ion-color-hymn-contrast: #ffffff;
        --ion-color-hymn-contrast-rgb: 255,255,255;
        --ion-color-hymn-shade: #c05757;
        --ion-color-hymn-tint: #de7373;
    }

    @include ion-color('facebook') {
        --ion-color-facebook: #3b5998;
        --ion-color-facebook-rgb: 59, 89, 152;
        --ion-color-facebook-contrast: #ffffff;
        --ion-color-facebook-contrast-rgb: 255, 255, 255;
        --ion-color-facebook-shade: #344e86;
        --ion-color-facebook-tint: #4f6aa2;
    }

    @include ion-color('google') {
        --ion-color-google: #cc181e;
        --ion-color-google-rgb: 204, 24, 30;
        --ion-color-google-contrast: #ffffff;
        --ion-color-google-contrast-rgb: 255, 255, 255;
        --ion-color-google-shade: #b4151a;
        --ion-color-google-tint: #d12f35;
    }

    @include ion-color('twitter') {
        --ion-color-twitter: #00aced;
        --ion-color-twitter-rgb: 0, 172, 237;
        --ion-color-twitter-contrast: #ffffff;
        --ion-color-twitter-contrast-rgb: 255, 255, 255;
        --ion-color-twitter-shade: #0097d1;
        --ion-color-twitter-tint: #1ab4ef;
    }
}
